import { Injectable, NgZone, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Url } from '../shared/constants/url-constants';
import { Base64 } from 'js-base64';
import { ApplicationConstant } from '../shared/constants/app-constant';
import { SharedService } from '../shared/service/shared.service';

const authHeaders = new HttpHeaders({
  'Content-Type': 'application/json'
});
const exposeXAuthHeader = new HttpHeaders({
  'Access-Control-Expose-Headers': 'X-Authorization',
});

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly sharedserive:SharedService
  ) {}

   get isLoggedIn() {
     if(localStorage.getItem('sessionToken')){
       return true;
     }
    return false;
  }

  signIn(formData: any): any {

    const body = JSON.stringify(formData);
    return this.http.post<any>(`${Url.USER}/login`, body, { headers: authHeaders, observe: 'response' })
      .pipe(
        tap(resp => {
          this.setAuth(resp);
        }));
  }

  signout() {
    return this.http.get(`${Url.USER}/logoutUser`)
      .pipe(finalize(() => this.purgeAuth()));
  }

  refreshToken() {
    return this.http.get<any>(`${Url.AUTHENTICATION}/refreshToken`, { observe: 'response' })
      .pipe(
        tap(resp => {
          this.setAuth(resp);
        }));
  }

  fetchGenders() {
    const href = `${Url.USER}/serviceUser/genders`;
    return this.http.get<any[]>(href);
  }

  signUp(payload: any) {
    payload.appId = ApplicationConstant.ApplicationID;
    payload.clientId = ApplicationConstant.clientId;
    payload.organizationId = ApplicationConstant.organizationId;
    return this.http.post<any>(`${Url.USER}/serviceUser/self-register`, payload, { observe: 'response' });
  }

  validatePasswordLink(payload: any) {
    payload.appId = ApplicationConstant.ApplicationID;
    payload.clientId = ApplicationConstant.clientId;
    const href = `${Url.USER}/user/validatePasswordLink`;
    return this.http.post<any>(href, payload);
  }

  validateAndSavePassword(payload) {
    payload.appId = ApplicationConstant.ApplicationID;
    payload.clientId = ApplicationConstant.clientId;
    const href = `${Url.USER}/user/validateAndSavePassword`;
    return this.http.post<any>(href, payload);
  }

  forgotPassword(payload) {
    const href = `${Url.USER}/user/forgotPassword`
    return this.http.post<any>(href, payload);
  }

  private purgeAuth() {
    localStorage.clear();
   this.sharedserive.closeDialogOnSessionTimeOut.emit(true);
   this.zone.run(() => this.router.navigate(['/sign-in']));
  }

  private setAuth(resp) {
    const xAuthToken = resp.headers.get('X-Authorization').split(' ');
    const token = xAuthToken[1];
    localStorage.setItem('sessionToken', token);
    if (resp.body.heartbeat) {
      localStorage.setItem('sessionParams', Base64.encode(JSON.stringify(resp.body)));
    }
    const payload = JSON.parse(atob(token.split('.')[1]));
    this.sharedserive.register(payload.userId, 'SESSION').subscribe();
  }
  currentUser() {
    if (localStorage.getItem('sessionToken')) {
      const payload = atob(localStorage.getItem('sessionToken').split('.')[1]);
      return JSON.parse(payload);
    }
    return null;
  }

  noticeBoardMessage() {
    const data = {"appId" : ApplicationConstant.ApplicationID , "clientId" : ApplicationConstant.clientId}; 
    const href = `${Url.AUTHENTICATION}/notification/notice`;
    return this.http.post<any>(href, data);  
}
  


}
